<template>
  <div>
    <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
      <NotiBar v-if="msgShow" :type="msgType" :msg="msgText" />
      <!--   -->

      <form @submit.prevent="saveForm">
        <fieldset class="form-block">
          <div class="form-container">

            <div class="input" :class="{ error: $v.user.name.$error }">
              <Label text="Fullname" :required="true" />
              <input type="text" v-model.trim="$v.user.name.$model">
            </div>

            <div class="input" :class="{ error: $v.user.email.$error }">
              <Label text="E-mail" :required="true" />
              <input type="email" v-model.trim="$v.user.email.$model">
            </div>

            <div class="input row2">
              <div class="col" :class="{ error: $v.user.phone.$error }">
                <Label text="Phone (number only)" />
                <input type="text" v-model.trim="$v.user.phone.$model">
              </div>
            
              <div class="col">
                <Label text="LINE user id" />
                <input type="text" v-model="user.line_uid" disabled>
              </div>

              <div class="input-description">
                <strong>LINE User id</strong> ของ User จะได้จากการกรอกเบอร์โทรที่มีการลงทะเบียนในระบบของ Robo แล้ว กรณีที่ต้องการยกเลิกการรับแจ้งเตือนมายัง User ท่านนี้ทำได้โดยการลบหมายเลขโทรศัพท์์ User นี้ออกหรือตั้งสถานะ User เป็น Disable เท่านั้น
              </div>
            </div>

            <div class="input row2">
              <div class="col">
                <Label text="Password" />
                <input type="password" v-model.trim="user.pass">
              </div>

              <div class="col" :class="{ error: $v.user.passre.$error }">
                <Label text="Re-password" />
                <input type="password" v-model.trim="$v.user.passre.$model">
              </div>
            </div>
            

            <div class="input row2">
              <div class="col">
                <Label text="Role" />
                <select name="u_status" v-model="user.role">
                  <option value="10">Content Editor</option>
                  <option value="40">Quantity Surveyor</option>
                  <option value="50">Project Manager</option>
                  <option value="90">Administrator</option>
                </select>
              </div>
              <div class="col">
                <Label text="Status" />
                <select name="u_status" v-model="user.status">
                  <option value="0">Disable</option>
                  <option value="9">Enable</option>
                </select>
              </div>
            </div>
          </div>
          
        </fieldset>

        <div class="button-block">
          <button type="submit" class="btn btn-update">
            <i class="fa fa-save fa-lg"></i> Save
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {cloneObject} from '@/utils/objects.js';
const { required, numeric, email} = require('vuelidate/lib/validators') //, sameAs

import NotiBar from '@/components/common/NotiBar.vue';
import Label from '@/components/common/form/Label.vue';

export default {
  components: {
    NotiBar,
    Label
  },
  data() {
    return {
      loaded: false,
      
      is_add: true,

      msgShow: false,
      msgType:'success',
      msgText:'',

      userId: '',
      user: {
        name:   '',
        email:  '',
        phone:  '',
        role: 10,
        status: 0,
        pass:   '',
        passre: '',
      },
    }
  },
  validations: {
    user: {
      name: {required}, //
      email: {required, email},
      phone: {numeric},
      // pass: {required},
      passre: {
        // sameAs: sameAs(function() {
        //   console.log(this.user.pass);
        //   console.log(this.user.passre);
        //   // this.$v.user.pass.$model;
        //   return this.user.pass;
        // })
      },
    }
  },
  created() {
    this.setForm();
  },
  watch:{
    $route() {
      this.setForm();

      this.msgShow = true;
      this.msgText = 'Save data complete';
    }
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    setForm:function() {
      this.$store.commit('setLoaderShow', true);

      if(!this.$route.params.id) {
        this.$store.commit('setPageTitle', 'Add User');
        this.$store.commit('setHeadTitle', 'Add User');
        this.$store.commit('setLoaderShow', false);
      }else{
        this.userId = this.$route.params.id;
        this.is_add = false;

        this.$store.commit('setPageTitle', 'Edit User');
        this.$store.commit('setHeadTitle', 'Edit User');
        this.$store.commit('setLoaderShow', true);

        apiRequest
          .get('user/'+this.userId)
          .then( (res) => {
            this.user = res.data.result;
            this.user.pass = '';
            this.user.passre = '';
            this.$store.commit('setLoaderShow', false);
          });
      }
    },
    resetForm() {
      if(this.is_add) {
        this.$router.push('/user/edit/' + this.userId).catch();
      }else{
        this.setForm();
        this.msgShow = true;
        this.msgText = 'Save data complete';
      }
    },
    saveForm:function(e) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        // this.err_message = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      } else {
        this.$store.commit('setLoaderShow', true);

        let post_data = cloneObject(this.user);
        let request = '/user/' + (this.userId==='' ? 'add' : 'update/'+this.userId);

        // if no insert new password in edit form, 
        // system will delete this input value before update into api
        if(this.userId!=='' && post_data.pass==='') {
          delete post_data.pass;
        }

        apiRequest
          .post(request, post_data)
          .then( (res) => {
            if(this.is_add) {
              this.userId = res.data.last_insert_id;
            }
            this.resetForm();
          });
      }
      e.preventDefault();
    },
  }
}
</script>


<style scoped>
.tb-data .col-name {width: 25%;}
.tb-data .col-user {width: auto;}
.tb-data .col-email {width: 25%;}
.tb-data .col-role {width: 15%;}
.tb-data .col-date {width: 15%;}
</style>